.manage-user-detail {
    @apply overflow-auto flex flex-col h-full;

    .content-1 {
        @apply flex w-[400px] sm:w-full;
    }

    .content-1-A {
        @apply flex-initial;
        @apply flex flex-col;
    }
    .content-1-B {
        @apply overflow-auto;
    }

    .content-2 {
        @apply flex-1;
    }
}
