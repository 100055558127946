/* common that use in JPL ------------------------------ */
.login-bg {
    /* background-image: url('@assets/img/generic/BgLogin.png'); */
    background-image: linear-gradient(
            rgba(0, 117, 161, 0.4),
            rgba(0, 117, 161, 0.4)
        ),
        url('@assets/img/gallery/BgLogin.png');

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-container {
    background: #fff;
}

.login-logo {
    margin-bottom: 20px;
    width: 160px;
}

/* IFB Styles here ------------------------------ */
.IFB-login-bg {
    /* background-image: url('@assets/img/generic/BgLogin.png'); */
    /* background-image:
        url('@assets/img/gallery/IFBLoginBg.png'); */

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
