.pills {
    @apply flex gap-x-2 w-max overflow-auto;
    .pills-item {
        @apply p-2 z-[0] text-size-S text-size-XS overflow-hidden;
    }
    .brand-white {
        color: var(--loglines-gray-v6);
        background-color: var(--loglines-white);
    }
    .brand-white-active {
        color: var(--loglines-white);
        background-color: var(--loglines-brand-v5);
    }
    .brand-gray {
        color: var(--loglines-gray-v6);
        background-color: var(--loglines-gray-v2);
    }
    .brand-gray-active {
        color: var(--loglines-white);
        background-color: var(--loglines-brand-v5);
    }
    .brand-v2 {
        color: var(--loglines-brand-v4);
        background-color: var(--loglines-brand-v2);
    }
    .brand-v2-active {
        color: var(--loglines-white);
        background-color: var(--loglines-brand-v4);
    }
    .brand-v2-disabled {
        color: var(--loglines-brand-v3);
        background-color: var(--loglines-brand-v2);
    }
}
