.progress-multiple-bars {
    @apply flex gap-1;
}

.progress-multiple-bars .bar-item-container {
    @apply relative rounded h-[20px] flex justify-center;
    color: var(--loglines-white);
}

.progress-multiple-bars .bar-tooltip {
    position: absolute;
    padding: 5px;
    border-radius: 4px;
    transition: opacity 0.3s ease;
    opacity: 0;
    white-space: nowrap;
    z-index: 1;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--loglines-gray-v8);
    color: var(--loglines-white);
    max-width: 200px;
    text-overflow: ellipsis;
    font-size: 12px;
}

.progress-multiple-bars .bar-value {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 4px;
    margin-right: 4px;
}

.progress-multiple-bars .bar-item-container:hover .bar-tooltip {
    opacity: 1;
}

.progress-multiple-bars .bar-value {
    @apply flex gap-1;
}
