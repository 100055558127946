:root {
  /* ------ Basic Loglines IFB Colors ------ */
  --loglines-ifb-less-aqua-1: #76C6CC;
  --loglines-ifb-less-blue-1: #6991CF;
  --loglines-ifb-less-blue-2: #5280C6;
  --loglines-ifb-less-green-1: #69BAA4;
  --loglines-ifb-less-orange-1: #C98167;
  --loglines-ifb-less-purple-1: #9D75AD;
  --loglines-ifb-less-red-1: #C9677F;
  --loglines-ifb-less-yellow-1: #E3CD9A;
}