body.bright-sky-blue-themes {
    /* ------ Dynamic Loglines Colors ------ */
    --loglines-brand-v1: #f0fafe;
    --loglines-brand-v2: #cceffc;
    --loglines-brand-v3: #99dffa;
    --loglines-brand-v4: #66d0f7;
    --loglines-brand-v5: #00b0f2;
    --loglines-brand-v6: #008dc2;
    --loglines-brand-v7: #006a91;
}
