.loglines-button {
    height: 40px;
    @apply pt-[2.6px] px-4 transition;
    @apply border rounded-logistical-radius;
    @apply text-center text-[12px] tracking-normal;
}
.loglines-button.button-icon {
    @apply !px-0 !pt-[1px] transition text-size-XL;
}

/* -------- Variant Button - Default ------------ */
.button-default {
    border-color: var(--loglines-gray-v4);
    color: var(--loglines-gray-v6);
}
.button-default:hover {
    background-color: var(--loglines-brand-v1);
    border-color: var(--loglines-brand-v4);
    color: var(--loglines-brand-v5);
}
.button-default:active {
    background-color: var(--loglines-brand-v2);
    border-color: var(--loglines-brand-v4);
    color: var(--loglines-brand-v5);
}
.button-default[disabled],
.button-default[disabled]:active,
button.button-brand:disabled {
    background-color: var(--loglines-gray-v1);
    border-color: unset;
    color: var(--loglines-white);
}

/* -------- Variant Button - Default Pagination------------ */
.button-default-pagination {
    border-color: var(--loglines-gray-v4);
    color: var(--loglines-gray-v6);
}
.button-default-pagination:hover {
    background-color: var(--loglines-brand-v1);
    border-color: var(--loglines-brand-v4);
    color: var(--loglines-brand-v5);
}
.button-default-pagination:active {
    background-color: var(--loglines-brand-v2);
    border-color: var(--loglines-brand-v4);
    color: var(--loglines-brand-v5);
}
.button-default-pagination[disabled],
.button-default-pagination[disabled]:active,
button.button-brand:disabled {
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
    color: var(--loglines-gray-v3);
}

/* -------- Variant Button - Default ------------ */

/* Brand Primary */
.button-brand {
    border-color: var(--loglines-brand-v5);
    background-color: var(--loglines-brand-v5);
    color: var(--loglines-white);
}
.button-brand:hover {
    background-color: var(--loglines-brand-v3);
    border-color: var(--loglines-brand-v3);
    color: var(--loglines-white);
}
.button-brand:active {
    border-color: var(--loglines-brand-v6);
    background-color: var(--loglines-brand-v6);
    color: var(--loglines-white);
}
.button-brand[disabled],
button.button-brand:disabled {
    border-color: var(--loglines-brand-v2);
    background-color: var(--loglines-brand-v2);
    color: var(--loglines-white);
}

/* Brand Outline */
.button-brand-inverse {
    border-color: var(--loglines-brand-v5);
    background-color: var(--loglines-white);
    color: var(--loglines-brand-v5);
}
.button-brand-inverse:hover {
    background-color: var(--loglines-brand-v1);
    border-color: var(--loglines-brand-v5);
    color: var(--loglines-brand-v5);
}
.button-brand-inverse:active {
    background-color: var(--loglines-brand-v2);
    border-color: var(--loglines-brand-v5);
    color: var(--loglines-brand-v5);
}
.button-brand-inverse[disabled],
.button-brand-inverse:disabled {
    border-color: var(--loglines-brand-v2);
    color: var(--loglines-brand-v2);
    background-color: var(--loglines-white);
}

/* Blue Brand */
.button-brand-v2 {
    border-color: var(--loglines-brand-v4);
    background-color: var(--loglines-brand-v4);
    color: var(--loglines-white);
}
.button-brand-v2:hover {
    border-color: var(--loglines-brand-v1);
    background-color: var(--loglines-brand-v1);
    color: var(--loglines-brand-v4);
}
.button-brand-v2:active {
    border-color: var(--loglines-brand-v4);
    background-color: var(--loglines-brand-v2);
    color: var(--loglines-brand-v4);
}
.button-brand-v2[disabled],
button.button-brand-v2:disabled {
    border-color: var(--loglines-brand-v2);
    background-color: var(--loglines-brand-v2);
    color: var(--loglines-white);
}

/* Brand v2 Inverse */
.button-brand-v2-inverse {
    border-color: var(--loglines-white);
    background-color: var(--loglines-brand-v2);
    color: var(--loglines-brand-v4);
}
.button-brand-v2-inverse:hover {
    border-color: var(--loglines-white);
    background-color: var(--loglines-brand-v4);
    color: var(--loglines-brand-v2);
}
.button-brand-v2-inverse[disabled],
.button-brand-v2-inverse:disabled {
    border-color: var(--loglines-brand-v2);
    color: var(--loglines-brand-v3);
    background-color: var(--loglines-brand-v2);
}

/* Red */
.button-red {
    border-color: var(--loglines-red-v3);
    background-color: var(--loglines-red-v3);
    color: var(--loglines-white);
}
.button-red:hover {
    border-color: var(--loglines-red-v2);
    background-color: var(--loglines-red-v2);
    color: var(--loglines-white);
}
.button-red:active {
    border-color: var(--loglines-red-v4);
    background-color: var(--loglines-red-v4);
    color: var(--loglines-white);
}
.button-red[disabled],
.button-red:disabled {
    border-color: var(--loglines-red-v1);
    background-color: var(--loglines-red-v1);
    color: var(--loglines-white);
}

/* Red Inverse */
.button-red-inverse {
    border-color: var(--loglines-red-v3);
    color: var(--loglines-red-v3);
    background-color: var(--loglines-white);
}
.button-red-inverse:hover {
    border-color: var(--loglines-red-v3);
    background-color: var(--loglines-red-v1);
    color: var(--loglines-red-v3);
}
.button-red-inverse:active {
    border-color: var(--loglines-red-v3);
    background-color: var(--loglines-red-v2);
    color: var(--loglines-red-v3);
}
.button-red-inverse[disabled],
.button-red-inverse:disabled {
    border-color: var(--loglines-red-v1);
    background-color: var(--loglines-white);
    color: var(--loglines-red-v1);
}

/* yellow */
.button-yellow {
    border-color: var(--loglines-yellow-v3);
    background-color: var(--loglines-yellow-v3);
    color: var(--loglines-white);
}
.button-yellow:hover {
    border-color: var(--loglines-yellow-v2);
    background-color: var(--loglines-yellow-v2);
    color: var(--loglines-white);
}
.button-yellow:active {
    border-color: var(--loglines-yellow-v4);
    background-color: var(--loglines-yellow-v4);
    color: var(--loglines-white);
}
.button-yellow[disabled],
.button-yellow:disabled {
    border-color: var(--loglines-yellow-v1);
    background-color: var(--loglines-yellow-v1);
    color: var(--loglines-white);
}

/* yellow Inverse */
.button-yellow-inverse {
    border-color: var(--loglines-yellow-v3);
    color: var(--loglines-yellow-v3);
    background-color: var(--loglines-white);
}
.button-yellow-inverse:hover {
    border-color: var(--loglines-yellow-v3);
    background-color: var(--loglines-yellow-v1);
    color: var(--loglines-yellow-v3);
}
.button-yellow-inverse:active {
    border-color: var(--loglines-yellow-v3);
    background-color: var(--loglines-yellow-v2);
    color: var(--loglines-yellow-v3);
}
.button-yellow-inverse[disabled],
.button-yellow-inverse:disabled {
    border-color: var(--loglines-yellow-v1);
    background-color: var(--loglines-white);
    color: var(--loglines-yellow-v1);
}

/* green */
.button-green {
    border-color: var(--loglines-green-v3);
    background-color: var(--loglines-green-v3);
    color: var(--loglines-white);
}
.button-green:hover {
    border-color: var(--loglines-green-v2);
    background-color: var(--loglines-green-v2);
    color: var(--loglines-white);
}
.button-green:active {
    border-color: var(--loglines-green-v4);
    background-color: var(--loglines-green-v4);
    color: var(--loglines-white);
}
.button-green[disabled],
.button-green:disabled {
    border-color: var(--loglines-green-v1);
    background-color: var(--loglines-green-v1);
    color: var(--loglines-white);
}

/* green Inverse */
.button-green-inverse {
    border-color: var(--loglines-green-v3);
    color: var(--loglines-green-v3);
    background-color: var(--loglines-white);
}
.button-green-inverse:hover {
    border-color: var(--loglines-green-v3);
    background-color: var(--loglines-green-v1);
    color: var(--loglines-green-v3);
}
.button-green-inverse:active {
    border-color: var(--loglines-green-v3);
    background-color: var(--loglines-green-v2);
    color: var(--loglines-green-v3);
}
.button-green-inverse[disabled],
.button-green-inverse:disabled {
    border-color: var(--loglines-green-v1);
    background-color: var(--loglines-white);
    color: var(--loglines-green-v1);
}
