@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.loglines-modal {
  /* Initial state */
  opacity: 0;
  animation-fill-mode: forwards; /* Keep the animation state after it ends */
}

.loglines-modal.fade-in {
  opacity: 1;
  animation: fadeIn 200ms ease-in; /* 200ms for fade-in */
}

.loglines-modal.fade-out {
  animation: fadeOut 50ms ease-out; /* 50ms for fade-out */
}
