.infoCardGroupTitle {
    @apply text-[20px]
}

.infoCardGroupDesc {
    @apply text-[14px]
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .infoCardGroupTitle {
        @apply text-[16px]
    }
    
    .infoCardGroupDesc {
        @apply text-[12px]
    }
}