.pagination-container {
    @apply flex flex-row w-fit self-start py-2 pl-2;
}

.pagination {
    background-color: var(--loglines-white);
    @apply flex m-0 items-center justify-start w-fit list-none flex-row;
    @apply h-12 rounded;
}

.page-of {
    @apply m-[2px] text-size-M;
}
