.create-user {
    .content {
        @apply h-[calc(100vh-225px)] overflow-auto flex sm:flex-wrap gap-5;
        @apply p-5 sm:p-5;
    }

    .content-flex {
        @apply sm:w-full;
    }
    .content-flex-1 {
        @apply w-1/2 sm:w-full space-y-3;
    }
    .content-flex-2 {
        @apply w-1/2 sm:w-full space-y-3;
    }
}
