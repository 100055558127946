/* Untuk layar dengan lebar maksimum 600px */
.text-title {
    font-size: 12px;
}

/* @media (max-width: 1550px) and (min-width: 900px) {
    .text-title {
        font-size: 8px;
    }
} */
