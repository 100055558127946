.text-title-rechart {
    @apply text-[16px]
}

.text-decs-rechart {
    @apply text-[16px]
}

.w-card-donat-rechart {
    @apply w-[113px]
}

.h-donat-rechart {
    @apply !h-[250px]
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .text-title-rechart {
        @apply text-[12px]
    }

    .text-decs-rechart {
        @apply text-[10px]
    }

    .w-card-donat-rechart {
        @apply w-[80px]
    }

    .h-donat-rechart {
        @apply !h-[200px]
    }
}