.info-card-icon {
    @apply text-[24px] px-2;
}

.info-card-count {
    @apply text-[20px];
}

.info-card-title {
    @apply text-[16px];
}

.info-card-item {
    @apply text-[14px] leading-4;
}

.height-news-data {
    @apply h-[calc(100vh-41rem)];
}

.text-title-dashboard {
    @apply text-[20px];
}

.text-title-card-dashboard {
    @apply text-[16px];
}

.text-desc-dashboard {
    @apply text-size-XS;
}

.text-data-card-dashboard {
    @apply text-[16px];
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .info-card-icon {
        @apply text-[20px] px-1;
    }

    .info-card-count {
        @apply text-[16px];
    }

    .info-card-title {
        @apply text-[12px];
    }

    .info-card-item {
        @apply text-[12px] leading-3;
    }

    .text-title-dashboard {
        @apply text-[16px];
    }

    .text-title-card-dashboard {
        @apply text-[14px];
    }

    .text-desc {
        @apply text-[12px];
    }

    .text-data-card-dashboard {
        @apply text-[14px];
    }
}

@media (min-height: 850px) and (max-height: 910px) {
    .height-news-data {
        @apply h-[calc(100vh-41rem)];
    }
}

@media (min-height: 700px) and (max-height: 849px) {
    .height-news-data {
        @apply h-[calc(100vh-36.5rem)];
    }
}

.ri-book-marked-line::before {
    content: '\ead9';
}
