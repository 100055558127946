.estimation-text {
    @apply text-[14px]
}

.estimation-card {
    @apply h-[30px] text-[12px]
}

.estimation-flag {
    @apply w-[32px]
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .estimation-text {
        @apply text-[12px]
    }

    .estimation-card {
        @apply h-[25px] text-[10px]
    }

    .estimation-flag {
        @apply w-[25px]
    }
}

@media (min-height: 850px) and (max-height: 910px) {}

@media (min-height: 700px) and (max-height: 849px) {}